<template>
  <div>
 
    <div
      class="modal fade"
      id="ImageFileModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      ref="modal"
    >
      <div class="modal-dialog modal-xl">
        <div class="modal-content">
          <div class="container py-2 px-3" style="height:90vh">
            <div
              class="pt-3 pb-4 px-3 d-flex justify-content-between align-items-center"
            >
              <div>
                <p
                  class="m-0"
                  style="
                    font-family: Poppins;
                    font-size: 24px;
                    font-weight: 700;
                    line-height: 36px;
                    text-align: left;
                  "
                >
                  Choose Image
                </p>
              </div>
              <div>
                <button
                  class="btn"
                  style="
                    border-radius: 6px;
                    border: 1px solid rgba(31, 31, 40, 0.08);
                    background: #f9f9f9;
                  "
                  @click="closeModal"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M11.2166 10L15.8333 14.6167V15.8333H14.6166L9.99996 11.2167L5.38329 15.8333H4.16663V14.6167L8.78329 10L4.16663 5.38334V4.16667H5.38329L9.99996 8.78334L14.6166 4.16667H15.8333V5.38334L11.2166 10Z"
                      fill="#B3AFB6"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div class="d-flex justify-content-start px-3 mb-3">
              <div
                class="linkDiv mr-3"
                :class="selectedView == 'gallery' ? 'selectedDiv' : null"
                @click="updateView('gallery')"
              >
                <p
                  class="link-normal"
                  style="text-align: start"
                  :style="selectedView == 'gallery' ? 'color:#4d1b7e;' : null"
                >
                  My Gallery
                </p>
              </div>
              <div
                class="linkDiv mr-3"
                :class="selectedView == 'stockImages' ? 'selectedDiv' : null"
                @click="updateView('stockImages')"
              >
                <p
                  class="link-normal m-0"
                  style="text-align: start"
                  :style="
                    selectedView == 'stockImages' ? 'color:#4d1b7e;' : null
                  "
                >
                  Search Unsplash
                </p>
              </div>
              <div
                class="linkDiv mr-3"
                :class="selectedView == 'upload' ? 'selectedDiv' : null"
                @click="updateView('upload')"
              >
                <p
                  class="link-normal m-0"
                  style="text-align: start"
                  :style="selectedView == 'upload' ? 'color:#4d1b7e;' : null"
                >
                  Embed Image link
                </p>
              </div>
            </div>

            <div v-if="selectedView == 'gallery'" class="px-3 py-3">
              <div class="">
                <p class="titleHead">Upload New Image:</p>

                <div
                  @dragenter.prevent="setActive"
                  @dragover.prevent="setActive"
                  @dragleave.prevent="setInactive"
                  @drop.stop.prevent="dragFile"
                  @click="$refs.fileInput.click()"
                  class="w-100 h-100 d-flex align-items-center justify-content-center"
                  style="
                    cursor: pointer;
                    padding: 16px 24px;
                    border-radius: 8px;
                    border: 1px solid #eaecf0;
                    background: #fff;
                  "
                >
                  <input
                    type="file"
                    accept="image/png,image/gif,image/jpeg,image/svg+xml"
                    style="display: none"
                    ref="fileInput"
                    @change="uploadFile"
                  />
                  <div class="">
                    <div class="d-flex flex-column align-items-center py-3">
                      <svg
                        width="46"
                        height="46"
                        viewBox="0 0 46 46"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <rect
                          x="3"
                          y="3"
                          width="40"
                          height="40"
                          rx="20"
                          fill="#EADDF6"
                        />
                        <rect
                          x="3"
                          y="3"
                          width="40"
                          height="40"
                          rx="20"
                          stroke="#F4EFF9"
                          stroke-width="6"
                        />
                        <g clip-path="url(#clip0_154_1904)">
                          <path
                            d="M26.3333 26.3333L23 23M23 23L19.6666 26.3333M23 23V30.5M29.9916 28.325C30.8044 27.8819 31.4465 27.1807 31.8165 26.3322C32.1866 25.4836 32.2635 24.536 32.0351 23.6389C31.8068 22.7418 31.2862 21.9463 30.5555 21.3779C29.8248 20.8095 28.9257 20.5006 28 20.5H26.95C26.6977 19.5244 26.2276 18.6186 25.5749 17.8508C24.9222 17.083 24.104 16.4732 23.1817 16.0672C22.2594 15.6611 21.2571 15.4695 20.2501 15.5066C19.243 15.5437 18.2575 15.8086 17.3676 16.2814C16.4777 16.7542 15.7066 17.4226 15.1122 18.2363C14.5177 19.0501 14.1155 19.9879 13.9358 20.9795C13.756 21.971 13.8034 22.9904 14.0743 23.961C14.3452 24.9317 14.8327 25.8282 15.5 26.5833"
                            stroke="#4D1B7E"
                            stroke-width="1.66667"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_154_1904">
                            <rect
                              width="20"
                              height="20"
                              fill="white"
                              transform="translate(13 13)"
                            />
                          </clipPath>
                        </defs>
                      </svg>
                      <div class="mt-3" style="text-align: center">
                        <p class="m-0 mb-2 dropTitle" v-if="spinner">
                          <span style="color: #0397cc"
                            >Your media is being uploaded.</span
                          >
                          This may take a moment.
                        </p>
                        <div
                          v-if="spinner"
                          class="spinner-border spinner-border-sm"
                          style="color: #0397cc"
                          role="status"
                        >
                          <span class="sr-only">Loading...</span>
                        </div>
                        <div class="text-center" v-else>
                          <p class="m-0 mb-2 dropTitle">
                            <span style="color: #0397cc">Click to upload</span>
                            or drag and drop
                          </p>
                          <p class="m-0 dropSubtitle">
                            PNG, JPG or GIF (max. 5MB)
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="my-3 d-flex align-items-center justify-content-between">

                  <div style="height: 1px;background: #EAECF0; width:100%"></div>
                  <div class="mx-5"><span style="font-family: Poppins;
font-size: 14px;
font-weight: 500;
line-height: 20px;
text-align: left;color: #73738D;

">OR</span></div>
                  <div style="height: 1px;background: #EAECF0; width:100%"></div>

                </div>
              </div>

              <!-- Loader Comp                   -->
              <div v-if="isLoading " >
                <LoaderComp style="margin-top:10rem;" />
              </div>
              <!-- Images Comp start -->
              <div v-else>
                <!-- Delete Image View start-->
                <div v-if="deleteView">
                  <div
                    class="d-flex align-items-center"
                    style="min-height: 300px"
                  >
                    <div class="container py-5">
                      <div class="row">
                        <div
                          class="w-100 d-flex flex-column justify-content-between align-items-center"
                        >
                          <p
                            style="
                              font-family: Poppins;
                              font-size: 24px;
                              font-weight: 700;
                              line-height: 36px;
                              text-align: center;
                            "
                          >
                            Do you wish to delete the Image ?
                          </p>
                          <p class="titleHead text-center">
                            This action is irreversible. Once deleted, it cannot
                            be restored.
                          </p>
                          <div
                            class="d-flex align-items-center w-100 justify-content-center"
                          >
                            <button
                              class="CreateBtn mr-5 outline-none text-white outline-none border-0 px-4 py-2"
                              style="background: #ea1601cc; border-radius: 6px"
                              @click="deleteImageFunc"
                            >
                              <span v-if="!deleteImageSpinner">Delete</span>
                              <div
                                v-else
                                class="spinner-border spinner-border-sm"
                                role="status"
                              >
                                <span class="sr-only">Loading...</span>
                              </div>
                            </button>
                            <button
                              @click="cancelDeleteModal"
                              class="outline-none text-white outline-none border-0 px-3 py-2 see-demo-btn"
                              style="background: #4d1b7eaa; border-radius: 6px"
                            >
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- Delete Image View end-->
                <!-- Image Gallery View start -->
                <div v-else>
                  <p class="titleHead m-0 mb-3">Choose from your gallery:</p>
                  <div
                    class="row mt-2 GalleryContainerHeight"
                    style=" height: 100%; overflow-y: scroll"
                    v-if="getFilteredImages.length"
                  >
                    <div
                      class="col-12 col-md-6 col-lg-3 mb-3 imageContainer"
                      style="cursor: pointer"
                      v-for="(file, fileIndex) in getFilteredImages"
                      :key="fileIndex"
                      @click="selectImageDone(file.lg_image)"
                    >
                      <div style="position: relative; overflow: hidden">
                        <LazyImage
                          :key="file.lg_image"
                          :style="
                            imageSrc == file.lg_image
                              ? 'border: 2px solid #0397CC;'
                              : null
                          "
                          style="
                            height: 150px;
                            width: 100%;
                            aspect-ratio: 3 / 2;
                            width: 100%;
                            border-radius: 12px;
                          "
                          :src="ImageSrc(file.lg_image)"
                          alt="Img"
                        />

                        <div
                          class="deleteContainer"
                          style="position: absolute; top: 3%; right: 3%"
                        >
                          <button
                            @click.stop="openDeleteModal(file)"
                            class="btn"
                            style="
                              border-radius: 6px;
                              background: rgba(255, 255, 255, 0.7);
                              backdrop-filter: blur(1px);
                            "
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M13.5 4.5V13.5C13.5 14.3284 12.8284 15 12 15H6C5.17157 15 4.5 14.3284 4.5 13.5V4.5M3 4.5H15M11.25 4.5V3.75C11.25 2.92157 10.5784 2.25 9.75 2.25H8.25C7.42157 2.25 6.75 2.92157 6.75 3.75V4.5"
                                stroke="#F0174B"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                        </div>

                        <div
                          v-if="imageSrc == file.lg_image"
                          class="selectedImageContainer"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="40"
                            height="40"
                            viewBox="0 0 40 40"
                            fill="none"
                          >
                            <path
                              d="M20 3.33334C10.8334 3.33334 3.33337 10.8333 3.33337 20C3.33337 29.1667 10.8334 36.6667 20 36.6667C29.1667 36.6667 36.6667 29.1667 36.6667 20C36.6667 10.8333 29.1667 3.33334 20 3.33334ZM16.6667 28.3333L8.33337 20L10.6834 17.65L16.6667 23.6167L29.3167 10.9667L31.6667 13.3333L16.6667 28.3333Z"
                              fill="white"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="p-5" v-else>
                    <p class="text-center">No Images Found</p>
                  </div>
                </div>
                <!-- Image Gallery View end -->
              </div>
              <!-- Images Comp End -->
            </div>

            <div v-if="selectedView == 'upload'" class="px-3 py-3">
              <p class="titleHead">Insert image link here:</p>
              <div
                class="searchInput d-flex align-items-center w-100"
                style="border: 0.8px solid #d1d1d1"
              >
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  placeholder="https://"
                  v-model="imageLink"
                />
               
                <button
                  @click="uploadImageLink"
                  :disabled="imageLink == ''"
                  class="btn"
                  style="
                    padding: 8px 16px;
                    border-radius: 6px;
                    background: #4d1b7e;
                    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
                    color: #fff;
                  "
                >
                  Insert
                </button>
              </div>
            </div>
            <div class="px-3 py-3" v-if="selectedView == 'stockImages'">
              <p class="titleHead">Search image using Unsplash</p>
              <div
                class="searchInput d-flex align-items-center w-100 pr-3 py-2"
                style="border: 0.8px solid #d1d1d1"
              >
                <input
                  type="text"
                  class="mx-2 flex-grow-1"
                  placeholder="Search Images from Unsplash"
                  v-model="unsplashSearchKey"
                />
                <div v-if="unsplashSearchKey != ''">
                  <button
                    class="btn py-0 my-0 font-weight-bold"
                    @click="unsplashSearchKey = ''"
                    style="color: #d1d1d1"
                  >
                    X
                  </button>
                </div>
              </div>

              <div class="mt-3" v-if="isUnsplashLoading">
                <div class="unSplashImagesContainer">
                  <div class="bg-preload imgContainer horizontal" style="">
                    <div class="into-preload"></div>
                  </div>
                  <div class="bg-preload imgContainer vertical" style="">
                    <div class="into-preload"></div>
                  </div>
                  <div class="bg-preload imgContainer vertical" style="">
                    <div class="into-preload"></div>
                  </div>

                  <div class="bg-preload imgContainer horizontal" style="">
                    <div class="into-preload"></div>
                  </div>
                </div>
              </div>

              <div class="mt-3" v-else>
                <div v-if="unsplashImagesData.length > 0">
                  <p
                    style="
                      color: #1f1f28;
                      font-family: Poppins;
                      font-size: 14px;
                      font-style: normal;
                      font-weight: 400;
                      line-height: 20px; /* 142.857% */
                    "
                    v-if="!isUnsplashLoading && unsplashSearchKey !== ''"
                  >
                    Search results for
                    <span style="font-weight: 600;">"{{
                      unsplashSearchKey
                    }}"</span> images
                  </p>
                  <div class="unSplashImagesContainer unsplashContainerHeight" >
                    <div
                      v-for="(stockImage, index) in unsplashImagesData"
                      :key="stockImage.id"
                      class="imgContainer"
                      :class="[
                        ImageLayout(stockImage.width, stockImage.height),
                        stockImage.isLoading ? 'loaderSpinner' : null,
                      ]"
                      @click="chooseImage(stockImage, index)"
                    >
                      <LazyImage
                        :key="stockImage.id"
                        :style="
                          imageSrc == stockImage.urls.regular
                            ? 'border:2px solid #ffa201'
                            : null
                        "
                        :src="stockImage.urls.regular"
                        :alt="stockImage.alt_description"
                      />
                      <div class="endorseConatiner">
                        <p class="m-0">
                          Photo by
                          <a
                            :href="`${stockImage.user.links.html}?utm_source=Quizell&utm_medium=referral`"
                            target="_blank"
                            >{{ stockImage.user.name }}</a
                          >
                          on
                          <a
                            href="https://unsplash.com/?utm_source=Quizell&utm_medium=referral"
                            target="_blank"
                            >Unsplash</a
                          >
                        </p>
                      </div>
                      <div class="spinnerContainer" v-if="stockImage.isLoading">
                        <b-spinner variant="light"></b-spinner>
                      </div>
                    </div>
                  </div>
                  <div
                    class="d-flex justify-content-center align-items-center pt-1"
                    v-if="unsplashSearchKey !== ''"
                  >
                    <div
                      class="unSplashPaginationContainer d-flex align-items-center"
                    >
                      <button
                        class="btn paginationBtn"
                        :disabled="unsplashCurrentPage == 1"
                        @click="unsplashCurrentPage -= 1"
                      >
                        Prev
                      </button>
                      <span class="">{{ unsplashCurrentPage }}</span>
                      <button
                        class="btn paginationBtn"
                        :disabled="unsplashCurrentPage == unsplashTotalPages"
                        @click="unsplashCurrentPage += 1"
                      >
                        Next
                      </button>
                    </div>
                  </div>
                </div>
                <div v-else>
                  <p>
                    No Images Found for
                    <span class="font-weight-bold">{{
                      unsplashSearchKey
                    }}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoaderComp from "../component/LoaderComp.vue";
import axios from "axios";
import { debounce } from "lodash";
import $ from "jquery";
import globalMixin from "../../../GlobalMixins/getSignedURLMixin.js";
export default {
  props: {
    selectedImage: String,
    openModal: Boolean,
  },
  mixins: [globalMixin],
  components: {
    LoaderComp,
  },
  data() {
    return {
      File: [],
      fileList: [],
      imageSrc: "",
      uploadingStatus: false,
      uploadingPercent: "89%",
      isLoading: true,
      imageLink: "",
      searchImage: "",
      spinner: false,
      dragActive: false,
      selectedView: "gallery",
      deleteView: false,
      deleteImageSpinner: false,
      deleteImageObj: null,

      // unsplash data
      unsplashSearchKey: "",
      unsplashPerPageImages: 30,
      isUnsplashLoading: false,
      unsplashImagesData: [],
      unsplashTotalPages: 0,
      unsplashCurrentPage: 1,
    };
  },
  mounted() {
    this.deleteView = false;
    $(this.$refs.modal).on("show.bs.modal", this.InitialOpenModal);
    $(this.$refs.modal).on("hidden.bs.modal", this.closeModal);
  },
  computed: {
    getFilteredImages() {
      return this.fileList.filter((item) => {
        const itemName = item.name ? item.name.toLowerCase() : "";
        return (
          this.searchImage.length === 0 ||
          itemName.includes(this.searchImage.toLowerCase())
        );
      });
    },
  },
  methods: {
    async chooseImage(image, index) {
      try {
        this.unsplashImagesData[index].isLoading = true;

        const { download_location } = image.links;
        const downloadUrl = `${download_location}?client_id=${process.env.VUE_APP_UNSPLASH_CLIENT_KEY}`;

        const response = await axios.get(downloadUrl);
        if (response.status == 200) {
          let { url } = response.data;
          this.selectImageDone(url);
        }
      } catch (error) {
        this.$toasted.show("Something went wrong! Please try again", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
      } finally {
        this.unsplashImagesData[index].isLoading = false;
      }
    },
    ImageLayout(width, height) {
      const ratio = width / height;

      if (ratio > 1) {
        return "horizontal";
      } else if (ratio < 1) {
        return "vertical";
      } else {
        return "square";
      }
    },
    debounceUnsplashInput: debounce(function () {
      this.fetchUnsplashImage(this.unsplashSearchKey);
    }, 1000),
    async fetchUnsplashImage(query) {
      try {
        this.isUnsplashLoading = true;
        const response = await axios.get(
          `${process.env.VUE_APP_UNSPLASH_API_URL}?query=${query}&page=${this.unsplashCurrentPage}&per_page=${this.unsplashPerPageImages}&client_id=${process.env.VUE_APP_UNSPLASH_CLIENT_KEY}`
        );
        if (response.status == 200) {
          let { data } = response;
          (this.unsplashImagesData = data.results.map((image) => {
            return {
              id: image.id,
              slug: image.slug,
              description: image.description,
              alt_description: image.alt_description,
              urls: image.urls,
              links: image.links,
              likes: image.likes,
              height: image.height,
              width: image.width,
              user: image.user,
              isLoading: false,
            };
          })),
            (this.unsplashTotalPages = data.total_pages);
        }
      } catch (error) {
        this.$toasted.show("Error Occured while fetching Unsplash Images", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        throw error;
      } finally {
        this.isUnsplashLoading = false;
      }
    },

    async deleteImageFunc() {
      if (this.deleteImageObj) {
        let deleteId = [];
        deleteId.push(this.deleteImageObj.id);

        this.deleteImageSpinner = true;

        try {
          let data = {
            image_ids: deleteId,
            quizId: parseInt(localStorage.getItem("QuizID")),
          };
          const response = await axios.post(`/upload/deleteImages`, data);
          if (response.status == 200 && response.data.data) {
            this.$toasted.show("Image successfully deleted!", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
            this.cancelDeleteModal();
            this.getImages();
          }
        } catch (error) {
          if (error) {
            this.$toasted.show("Error occured ", {
              theme: "toasted-primary",
              position: "bottom-center",
              duration: 2000,
            });
          }
          throw error;
        } finally {
          this.deleteImageSpinner = false;
          this.deleteView = false;
        }
      }
    },
    openDeleteModal(file) {
      this.deleteView = true;
      this.deleteImageObj = file;
    },
    cancelDeleteModal() {
      this.deleteImageObj = null;
      this.deleteView = false;
    },
    updateView(value) {
      this.selectedView = value;
    },
    openModalFunc() {
      if (this.selectedImage) {
        if (this.selectedImage.startsWith("/")) {
          this.imageSrc = this.selectedImage.substring(1);
        } else {
          this.imageSrc = this.selectedImage;
        }
      }
      this.spinner = false;
      this.deleteView = false;
      $("#ImageFileModal").modal("show");
    },
    ImageSrc(img) {
      return img.startsWith("upload") ? `/${img}` : img;
    },
    toggleSelectImage(path) {
      if (this.imageSrc == path) this.imageSrc = "";
      else this.imageSrc = path;
    },
    InitialOpenModal() {
      this.deleteView = false;
      this.selectedView = "gallery";
      this.deleteImageObj = null;
      this.getImages();
    },
    async getImages() {
      this.isLoading = true;
      try {
        let response = await axios.get("/images/list");
        if (response.status == 200) {
          this.fileList = response.data;
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error Occured", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
      } finally {
        this.isLoading = false;
      }
    },
    uploadFile(e) {
      this.uploadOnServer(e.target.files);
    },
    dragFile(e) {
      this.uploadOnServer(e.dataTransfer.files);
    },

    setActive() {
      this.dragActive = true;
    },
    setInactive() {
      this.dragActive = false;
    },
    async uploadImageLink() {
      this.spinner = true;

      try {
        let data = {
          image_link: this.imageLink,
        };
        const response = await axios.post(`/saveImageLink`, data);

        if (response.status == 200) {
          this.imageLink = "";
          this.updateFirstImage();
        }
      } catch (error) {
        if (error) {
          this.$toasted.show("Error occured ", {
            theme: "toasted-primary",
            position: "bottom-center",
            duration: 2000,
          });
        }
        throw error;
      } finally {
        this.spinner = false;
        this.dragActive = false;
      }
    },

    updateFirstImage() {
      axios.get("/images/list").then((response) => {
        this.fileList = response.data;
        this.imageSrc = this.fileList[0].lg_image;
        this.dragActive = false;
        this.isLoading = false;
        this.selectImageDone(this.imageSrc);
      });
    },
    uploadOnServer(files) {
      let templateId = localStorage.getItem("QuizID");
      let items = [];
      let validImageTypes = [
        "image/gif",
        "image/jpeg",
        "image/png",
        "image/svg+xml",
      ];
      let file = files[0];
      try {
        // for (const file of files) {
        let fileType = file.type;
        let fileName = file.name;
        if (validImageTypes.includes(fileType)) {
          let reader = new FileReader();
          reader.onload = async (e) => {
            let obj = {
              fileName: fileName,
              fileType: fileType,
              foderName: "gallery",
            };

            this.spinner = true;
            let getSignedURLResponse = await this.getSignedUrl(obj);
            if (getSignedURLResponse.data.ok) {
              let data = getSignedURLResponse.data;
              let origionalName = data.origionalName;
              let filePath = data.filePath;
              let postData = {
                origionalName: origionalName,
                filePath: filePath,
                templateId: templateId,
              };
              items.push(postData);
              this.spinner = true;
              let digitalSpacesResponse = await this.UploadSignedURLImage(
                data,
                e
              );
              if (digitalSpacesResponse && digitalSpacesResponse.ok) {
                var elements = digitalSpacesResponse.url.split("?");
                var fistElement = elements[0];
                var filePortion = fistElement.split("/").pop();
                items.forEach(async (element) => {
                  if (element.filePath.includes(filePortion)) {
                    try {
                      let data = element;
                      const finalResponse = await axios.post(
                        `${process.env.VUE_APP_API_BASE_URL}upload/storeImageData`,
                        data
                      );
                      if (finalResponse.status == 200) {
                        this.updateFirstImage();
                        this.spinner = false;
                      }
                    } catch (error) {
                      if (error) {
                        this.$toasted.show("Error occured", {
                          theme: "toasted-primary",
                          position: "bottom-center",
                          duration: 2000,
                        });
                        this.spinner = false;
                        throw error;
                      }
                    }
                  }
                });
              } else {
                this.$toasted.show("Error occured", {
                  theme: "toasted-primary",
                  position: "bottom-center",
                  duration: 2000,
                });
                this.spinner = false;
              }
            } else {
              this.$toasted.show("Error occured", {
                theme: "toasted-primary",
                position: "bottom-center",
                duration: 2000,
              });
              this.spinner = false;
            }
          };

          // Read in the image file as a Blob.
          reader.readAsArrayBuffer(file);
        }
        // }
      } catch (error) {
        this.$toasted.show("Error occured", {
          theme: "toasted-primary",
          position: "bottom-center",
          duration: 2000,
        });
        this.spinner = false;
        throw error;
      }
    },

    selectImageDone(imageSrc) {
      this.$emit("selectedImageCallbackModal", imageSrc);
      $("#ImageFileModal").modal("hide");
    },
    closeModal() {
      if (this.selectedImage) {
        if (this.selectedImage.startsWith("/")) {
          this.imageSrc = this.selectedImage.substring(1);
        } else {
          this.imageSrc = this.selectedImage;
        }
      }

      $("#ImageFileModal").modal("hide");
    },
  },
  watch: {
    selectedView(newValue, oldValue) {
      if (oldValue == "upload" && newValue == "gallery") {
        this.getImages();
      }
      if (newValue == "stockImages") {
        this.unsplashTotalPages = 0;
        this.unsplashCurrentPage = 1;
        this.fetchUnsplashImage("products");
      }
    },
    unsplashSearchKey() {
      if (this.unsplashSearchKey !== "") {
        this.debounceUnsplashInput();
      } else {
        // this.unsplashImagesData = [],
        this.unsplashTotalPages = 0;
        this.unsplashCurrentPage = 1;
      }
    },
    unsplashCurrentPage() {
      this.debounceUnsplashInput(this.unsplashSearchKey);
    },
  },
};
</script>

<style scoped>
.modal-content {
  border-radius: 20px;
  overflow: hidden;
}
.modal-btn {
  background: #ffa201;
  color: #ffffff;
  border: none;
}
.modal-btn:hover {
  color: #ffffff;
}

/* New Styles */
.titleHead {
  color: #1f1f28;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 142.857% */
}

.link-normal {
  color: #73738d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
}

.linkDiv {
  cursor: pointer;
}

.selectedDiv {
  border-radius: 4px 4px 0px 0px;
  border-bottom: 1px solid #4d1b7e;
}
.selectedDiv p {
  color: #4d1b7e;
}

.searchInput {
  border-radius: 8px;
  padding: 5px 4px 5px 10px;
  border: 1px solid #ededed;
  background: var(--Base-White, #fff);
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}
.searchInput input {
  background: transparent;
  border: none;
  outline: none;
}
.searchInput input:focus {
  border: none;
  outline: none;
}

.deleteContainer {
  display: none;
}

.imageContainer:hover .deleteContainer {
  display: block;
}

.selectedImageContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.deleteTitle {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 30px;
  line-height: 72px;
  color: #4d4950;
}
.deleteTitleSub {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #4d4950bb;
}

.imageInfo > p {
  font-family: "Poppins";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #161e34;
}

.dropTitle {
  color: #73738d;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px; /* 142.857% */
}
.dropSubtitle {
  color: #73738d;
  text-align: center;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
}

/* Unsplash Styles */
.unSplashImagesContainer {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); */
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-auto-rows: 200px;
  grid-gap: 5px;
  grid-auto-flow: dense;
  overflow-y: scroll;
}
.unSplashImagesContainer .imgContainer {
  border-radius: 6px;
  position: relative;
}
.unSplashImagesContainer .imgContainer img {
  width: 100%;
  height: 100%;
  border-radius: 6px;
}

.unSplashImagesContainer .imgContainer.horizontal {
  grid-column: span 2;
}
.unSplashImagesContainer .imgContainer.vertical {
  grid-row: span 2;
}

.unSplashImagesContainer .imgContainer .endorseConatiner {
  /* position:absolute;
  bottom:0;
  left:0; */
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 20px;
  line-height: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  color: rgb(153, 153, 153);
  font-size: 10px;
  text-align: center;
  padding: 0px 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: none;
}
.unSplashImagesContainer .imgContainer .spinnerContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.unSplashImagesContainer .imgContainer.loaderSpinner {
  filter: brightness(0.7);
}
.unSplashImagesContainer .imgContainer .endorseConatiner p {
  font-size: 10px;
}
.unSplashImagesContainer .imgContainer .endorseConatiner p a {
  color: #ffa201;
}
.unSplashImagesContainer .imgContainer:hover {
  cursor: pointer;
}

.unSplashImagesContainer .imgContainer:hover .endorseConatiner {
  display: block;
}

.unSplashPaginationContainer {
  border: 1px solid rgb(209, 209, 209);
}
.unSplashPaginationContainer span {
  padding: 6px 20px;
  background: #4d1b7e;
  color: #fff;
  font-weight: bold;
}
.unSplashPaginationContainer .paginationBtn:hover {
  background: #ffa201;
  color: #fff;
}

.unsplashContainerHeight{
  min-height:45vh;
  max-height:51vh;
}
.GalleryContainerHeight{
 
  max-height:26vh;
}

@media (min-width:1440px) {
  .unsplashContainerHeight{
    max-height:55vh;
  }
  .GalleryContainerHeight{
 
 max-height:32vh;
}
 
}
</style>
